// // Custom Theming for Angular Material
// // For more information: https://material.angular.io/guide/theming
// @use '@angular/material' as mat;
// // Plus imports for other components in your app.

// // Include the common styles for Angular Material. We include this here so that you only
// // have to load a single css file for Angular Material in your app.
// // Be sure that you only ever include this mixin once!
// @include mat.core();

// // Define the palettes for your theme using the Material Design palettes available in palette.scss
// // (imported above). For each palette, you can optionally specify a default, lighter, and darker
// // hue. Available color palettes: https://material.io/design/color/
// $store-primary: mat.define-palette(mat.$indigo-palette);
// $store-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// // The warn palette is optional (defaults to red).
// $store-warn: mat.define-palette(mat.$red-palette);

// // Create the theme object. A theme consists of configurations for individual
// // theming systems such as "color" or "typography".
// $store-theme: mat.define-light-theme((
//   color: (
//     primary: $store-primary,
//     accent: $store-accent,
//     warn: $store-warn,
//   )
// ));

// // Include theme styles for core and each component used in your app.
// // Alternatively, you can import and @include the theme mixins for each component
// // that you are using.
// @include mat.all-component-themes($store-theme);

// @import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto&display=swap');
// //@import "~@angular/material/prebuilt-themes/purple-green.css";
// @import "~@angular/material/prebuilt-themes/deeppurple-amber.css";
// @import "../node_modules/@angular/material/prebuilt-themes/deeppurple-amber.css";

// /* You can add global styles to this file, and also import other style files */
// /* You can add global styles to this file, and also import other style files */

// $primary-color-300: mat.get-color-from-palette($store-primary, 300);
// $primary-color-400: mat.get-color-from-palette($store-primary, 400);
// $primary-color-500: mat.get-color-from-palette($store-primary, 500);
// $primary-color-600: mat.get-color-from-palette($store-primary, 600);
// $primary-color-700: mat.get-color-from-palette($store-primary, 800);
// $primary-color-800: mat.get-color-from-palette($store-primary, 800);
// $primary-color-900: mat.get-color-from-palette($store-primary, 900);

// $max-width: 1100px;

// *{
//   font-family: Roboto;
//   font-size: 15px;
//   box-sizing: border-box;
// }

// html, body {
//   height: 100%;
// }

// body{
//   margin: 0;
// }

// .container{
//   padding-right: max(calc((100% - $max-width)/2), 25px) !important;
//   padding-left: max(calc((100% - $max-width)/2), 25px) !important;
//   margin-right: auto;
//   margin-left: auto;
// }

// .prevent-select {
//   -webkit-user-select: none;
//   -moz-user-select: none;
//   -ms-user-select: none;
//   user-select: none;
// }
// html, body { height: 100%; }
// body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

// .mat-mdc-snack-bar-container {
//   &.my-snack-bar {
//     --mdc-snackbar-container-color: #ffffff;
//     --mat-mdc-snack-bar-button-color: #ff4081;
//     --mdc-snackbar-supporting-text-color: #000000;
//   }
// }

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
